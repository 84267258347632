<template>
  <ul class="tournament-conditions">
    <pvp-tooltip v-if="isAnticheat">
      <li class="tournament-conditions__item">
        <Icon name="shield" :size="14" />
      </li>
      <template v-if="anticheatTooltipText" slot="tooltip">{{
        anticheatTooltipText
      }}</template>
    </pvp-tooltip>
    <pvp-tooltip v-if="isTrusted">
      <li class="tournament-conditions__item">
        <Icon name="gear-check" :size="14" />
      </li>
      <template v-if="trustTooltipText" slot="tooltip">{{
        trustTooltipText
      }}</template>
    </pvp-tooltip>
  </ul>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'TournamentConditions',
  components: {
    Icon,
  },
  props: {
    isTrusted: {
      type: Boolean,
      default: false,
    },
    trustTooltipText: {
      type: String,
      default: '',
    },
    isAnticheat: {
      type: Boolean,
      default: false,
    },
    anticheatTooltipText: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.tournament-conditions {
  display: flex;
  list-style-type: none;
}
.tournament-conditions__item {
  color: var(--main-color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: linear-gradient(180deg, #2c2d2e 0%, #0e0f0f 100%);
  border: 1px solid #444546;
  border-radius: 6px;
  margin-right: 6px;

  &:not(:last-child) {
    margin-right: 6px;
  }
}
</style>
