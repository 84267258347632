<template>
  <img :src="src" crossorigin="anonymous" />
</template>

<script>
import { FastAverageColor } from 'fast-average-color';

export default {
  name: 'ImageColorScanner',
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      scanner: '',
    };
  },

  computed: {
    options() {
      return {};
    },
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.scanner = new FastAverageColor();
      this.getColor();
    },
    async getColor() {
      const res = await this.scanner.getColorAsync(
        this.src,
        this.options,
      );
      this.$emit('received', res);
    },
  },
};
</script>
