<template>
  <div ref="card" class="tournament-card">
    <div class="tournament-card__bg">
      <ImageColorScanner
        v-if="tournament.bgImage.small"
        class="tournament-card__image"
        :src="tournament.bgImage.small"
        @received="setFadeColor"
      />
      <div class="tournament-card__fade" :class="fadeMofifyClass" />
    </div>

    <div class="tournament-card__content">
      <div class="tournament-card__tags">
        <TournamentConditions
          :is-trusted="tournament.isTrusted"
          :trust-tooltip-text="$t('tournament.trust')"
        />
        <game-tag :code="tournament.gameCode" :full-name="true" />
      </div>
      <BaseLink
        class="tournament-card__title"
        :to="tournament.link"
        @click.native="
          handleTournamentClickNative('переход на страницу турнира')
        "
      >
        {{ tournament.name }}
      </BaseLink>
      <div v-if="tournament.hasPrizes" class="tournament-card__fund">
        <div class="tournament-card__prize-title">
          {{ $t('tournaments.prizePool') }}
        </div>
        <pvp-tooltip
          v-for="(prize, key) in tournament.prizes"
          :key="key"
        >
          <div class="tournament-card__prize">
            <span>{{ prize.count }}</span>
            <icon :name="prize.icon" inline />
          </div>
          <template slot="tooltip">{{ prize.tooltip }}</template>
        </pvp-tooltip>
      </div>
      <div class="tournament-card__footer">
        <div class="tournament-card__buttons">
          <pvp-btn
            v-if="tournament.userInfo.canRegister"
            :is-loading="isRegisteringToTournament(id)"
            class="tournament-card__button"
            variant="secondary"
            @click="startRegistration(id)"
            @click.native="
              handleTournamentClickNative('кнопка регистрация')
            "
          >
            {{ $t('global.register') }}
          </pvp-btn>
          <pvp-btn
            v-else
            :to="tournament.link"
            class="tournament-card__button"
            variant="secondary"
            @click.native="
              handleTournamentClickNative(
                'переход на страницу турнира',
              )
            "
          >
            {{ $t('global.more') }}
          </pvp-btn>
        </div>
        <div v-if="showTimer" class="tournament-card__time">
          <span class="tournament-card__time-title"
            >{{ $t('tournament.timeToStart') }}:
          </span>
          <time-left
            class="tournament-card__time-value"
            :to="tournament.countDown.ts"
            @timeout="onTimeOut"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { pushEvents } from '@utils/metricEvents.js';
import ImageColorScanner from '@components/v2/utils/ImageColorScanner.vue';
import TournamentConditions from '@components/v2/TournamentConditions.vue';
import Icon from '@components/v2/utils/Icon.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';

export default {
  name: 'TournamentCard',
  components: {
    Icon,
    ImageColorScanner,
    TournamentConditions,
    BaseLink,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loadingState: {
        cancel: false,
        confirm: false,
        avarageColor: {},
      },
      focusedTournamentId: NaN,
      isCardColorDark: false,
      showTimer: true,
    };
  },
  computed: {
    ...mapGetters('tournamentRegistration', [
      'isRegisteringToTournament',
    ]),
    ...mapGetters('tournaments', ['getTournamentCard']),

    tournament() {
      return this.getTournamentCard(this.id);
    },

    fadeMofifyClass() {
      return this.isCardColorDark
        ? 'tournament-card__fade--dark'
        : 'tournament-card__fade--light';
    },
  },
  methods: {
    ...mapActions('tournamentRegistration', ['startRegistration']),

    onTimeOut() {
      this.showTimer = false;
    },

    setFadeColor(color) {
      this.$refs.card.style.setProperty(
        `--card-color`,
        `${color.hex}`,
      );
      this.isCardColorDark = color.isDark;
    },

    handleTournamentClickNative(clickType) {
      // ======================================================
      // METRICS Турниры. Клик по элементу блока рекомендуемые
      const tmrParams = {
        category: 'pvp',
        action: 'tourn_recom_click',
        label: clickType,
        url: window.location.href,
      };
      pushEvents('pvp_tourn_recom_click', tmrParams);
      // ======================================================
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.tournament-card {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  color: var(--main-color-white);
}

.tournament-card__bg {
  border-radius: inherit;
  position: absolute;
  inset: 0;
}

.tournament-card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tournament-card__fade {
  border-radius: inherit;
  position: absolute;
  inset: 0;
  box-shadow: inset 0px 0px 0px 1px rgba(245, 245, 247, 0.12);
}

.tournament-card__fade--dark {
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.2) 25%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    linear-gradient(
      180deg,
      rgba(52, 60, 107, 0) 31.25%,
      var(--card-color) 100%
    );
  @include min-tablet() {
    background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.6) 1.5%,
        rgba(0, 0, 0, 0) 93.59%
      ),
      linear-gradient(
        90deg,
        var(--card-color) 30%,
        rgba(52, 60, 107, 0) 65%
      );
  }
}

.tournament-card__fade--light {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.15) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
  @include min-tablet() {
    background: linear-gradient(
      81.5deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.3) 100%
    );
  }
}

.tournament-card__content {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 400px;
  padding: 16px;

  @include min-tablet() {
    min-height: 270px;
    padding: 24px;
  }
}

.tournament-card__tags {
  display: flex;
  align-items: center;

  @include min-tablet() {
    margin-bottom: auto;
  }
}

.tournament-card__trust-icon {
  display: flex;
  align-items: center;
  color: var(--additional-color-green);
  margin-right: 8px;
}

.tournament-card__game-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: linear-gradient(180deg, #2c2d2e 0%, #0e0f0f 100%);
  border: 1px solid #444546;
  border-radius: 6px;
  margin-right: 6px;
}

.tournament-card__game-name {
  font-size: 14px;
  line-height: 18px;
}

.tournament-card__title {
  text-decoration: none;
  color: inherit;

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.3px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: auto;
  margin-bottom: 12px;

  &:hover {
    text-decoration: none;
    color: inherit;
  }

  @include min-tablet() {
    margin-top: unset;
    order: -1;
    font-size: 18px;
    line-height: 22px;
  }
}

.tournament-card__fund {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 24px;
}

.tournament-card__prize-title {
  width: 100%;
}

.tournament-card__prize {
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  background: rgba(245, 245, 247, 0.12);
  backdrop-filter: blur(20px);
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.3px;
  border-radius: 30px;

  span:not(:last-child) {
    margin-right: 4px;
  }
}

.tournament-card__footer {
  @include min-tablet() {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.tournament-card__buttons {
  &:not(:last-child) {
    margin-bottom: 8px;
  }

  @include min-tablet() {
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
}

.tournament-card__button {
  width: 100%;
}

.tournament-card__time {
  text-align: center;

  @include min-tablet() {
    text-align: left;
  }
}

.tournament-card__prize-title,
.tournament-card__time-title {
  font-size: 12px;
  line-height: 16px;
}

.tournament-card__time-title {
  @include min-tablet() {
    margin-bottom: 2px;
  }
}

.tournament-card__time-value {
  font-size: 14px;
  line-height: 18px;
}

.tournament-card__time-title,
.tournament-card__time-value {
  @include min-tablet() {
    display: block;
  }
}
</style>
